import styled from 'styled-components';

export const StyledImg = styled.span`
  height: 20px;
`;

export const StyledIdenticon = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 1.125rem;
  background-color: black;
`;

export const StyledIcon = styled.img`
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 1.125rem;
`;