import styled from 'styled-components';

export const StyledLabel = styled.span`
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: ${ ({ theme }) => theme.color };
  font-size: ${ props => (props.fontSize ? props.fontSize : '1rem') };
  font-weight: ${ props => (props.bold ? 'bold' : '400') };
  margin-bottom: -1px;
  margin-top: 0;
  padding: 0 0 0 5px;
  text-align: start;
  transition: .3s;
  word-break: break-word;
`;