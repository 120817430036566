import styled from 'styled-components';
import FluidContainer from '../base/FlexContainer';

export const Table = styled(FluidContainer)`
  box-shadow: none;
  overflow: auto;
  width: 100%;
`;

export const Row = styled(FluidContainer)`
  border-radius: 10px;
  box-shadow: none;
  flex-wrap: ${ props => (props.wrap ? props.wrap : 'wrap') };
  flex: 1 1 auto;
  gap: 5px;
  justify-content: flex-start;
  overflow: ${ props => (props.overflow ? props.overflow : 'hidden auto') };
  padding: ${ props => (props.padding ? props.padding : 'wrap') };
  width: 100%;

  @media (max-width: 768px) {
    column-gap: 0;
    row-gap: 1rem;

    :last-child :not(:last-of-type) {
      text-align: center;
      border-bottom: 1px solid #3a3a3a;
    }
  }
`;

export const Col = styled.div`
  color: #333;
  flex: ${ props => (props.flex ? props.flex : 'justify') };
  font-size: 0.8rem;
  height: fit-content;
  max-width: ${ props => (props.maxWidth ? props.maxWidth : 'auto') };
  min-height: 20px;
  min-width: ${ props => (props.minWidth ? props.minWidth : 'auto') };
  padding: 5px 5px 5px 3px;
  text-align: ${ props => (props.align ? props.align : 'justify') };
  width: ${ props => (props.width ? props.width : '100%') };
`;