import { useContractCalls } from '@usedapp/core';
import { utils } from 'ethers';
import ARTTLending from '../abi/ArtTokenLending.json';
import { CONTRACTS } from '../utils/constants';
import { useStakedBalancesCount } from './useStakedBalancesCount';

const STAKING_ABI = new utils.Interface(ARTTLending.abi);
const STAKING_ADDRESS = CONTRACTS.BSC.STAKING_ADDRESS;

export function useStakedBalances() {
	const count = useStakedBalancesCount();
	const calls = [];

	for ( let i = 0; i < +count?.toString(); i++ ) {
		i &&
		calls.push({
			abi: STAKING_ABI,
			address: STAKING_ADDRESS,
			method: 'lentBalances',
			args: [ String(i) ],
		});
	}
	return useContractCalls(calls);
}
