import React from 'react';
import logo from '../../../assets/images/artt-tools-logo-300x100.png';
import FlexContainer from '../../base/FlexContainer/FlexContainer';
import { Brand, StyledNavLink } from './Menu.styled';

const Menu = () => {
	return (
		<FlexContainer className='header-left' radiusBottomLeft='0' radiusBottomRight='0' >
			<Brand >
				<a href='https://artt.network' target='_self' rel="noopener noreferrer" >
					<img src={ logo } alt='brand' width='150' height='50' />
				</a>
			</Brand >
			<FlexContainer className='menu' >
				<nav>
					<ul>
						<li>
							<a href='https://buy.artt.network' target='_self' rel="noopener noreferrer">Buy</a>
						</li>
						<li>
							<StyledNavLink id='lend' to='/lend' >Lend</StyledNavLink >
						</li>
					</ul>
				</nav>
			</FlexContainer >
		</FlexContainer >
	);
};

export default Menu;
