import styled from 'styled-components';
import FluidContainer from '../base/FlexContainer';

export const Row = styled(FluidContainer)`
  align-items: center;
  background-color: ${ ({ theme }) => theme.label.backgroundColor };
  border-radius: 10px;
  border: ${ ({ theme }) => theme.border };
  box-shadow: none;
  color: ${ ({ theme }) => theme.color };
  flex-wrap: nowrap;
  flex: 1 1 auto;
  gap: 10px;
  justify-content: flex-start;
  margin-right: 1rem;
  min-height: 70px;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Col = styled.div`
  color: ${ ({ theme }) => theme.color };
  font-size: .8rem;
  font-weight: 400;
  height: fit-content;
  margin: 0;
  max-width: ${ props => (props.maxWidth ? props.maxWidth : 'auto') };
  min-height: 20px;
  padding: 5px;
  text-align: ${ props => (props.align ? props.align : 'left') };
  width: ${ props => (props.width ? props.width : '100%') };
  line-height: 1.5;

  @media (max-width: 768px) {
    min-width: 100% !important;
  }
`;

