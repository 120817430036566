import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import Footer from './components/Footer';
import Header from './components/Header';
import { Global } from './global/global';
import { darkTheme, lightTheme } from './global/theme';
import Bridge from './routes/Bridge';
import Home from './routes/Home';
import Lend from './routes/Lend';

const App = ({ widget }) => {
	const currentTheme = localStorage.getItem('theme');
	const [ theme, setTheme ] = useState(currentTheme);

	// useEffect(() => {
	// 	async function loadWidget() {
	// 		widget.create();
	// 	}
	//
	// 	loadWidget().then(() => {
	// 		const elementExists = document.querySelector('.matic-widget.matic-widget--center');
	// 		elementExists && elementExists.classList.add('widget--hidden');
	// 	});
	// }, [ widget ]);

	const toggleTheme = () => {
		if ( theme === 'light' ) {
			setTheme('dark');
			localStorage.setItem('theme', 'dark');
		} else {
			setTheme('light');
			localStorage.setItem('theme', 'light');
		}
	};

	return (
		<React.Fragment >
			<ThemeProvider theme={ theme === 'light' ? lightTheme : darkTheme } >
				<Global />
				<BrowserRouter >
					<Header toggleTheme={ toggleTheme } theme={ theme } />
					<Routes >
						<Route exact path='/*' element={ <Home /> } />
						<Route exact path='/bridge/*' element={ <Bridge widget={ widget } /> } />
						<Route exact path='/lend' element={ <Lend /> } />
					</Routes >
				</BrowserRouter >
				<Footer />
				<ToastContainer position='bottom-right' autoClose={ 5000 } hideProgressBar={ false } newestOnTop
				                closeOnClick rtl={ false } pauseOnFocusLoss draggable pauseOnHover
				                toastStyle={ {
					                backgroundColor: theme === 'light'
						                ? lightTheme.backgroundColor
						                : darkTheme.backgroundColor,
					                color: theme === 'light'
						                ? lightTheme.color
						                : darkTheme.color,
					                fontSize: '1rem'
				                } }
				/>
			</ThemeProvider >
		</React.Fragment >
	);
};

export default App;
