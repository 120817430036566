import React from 'react';
import { StyledFooter } from './Footer.styled';
import logo from '../../assets/images/logo-artt-token-256x256.png';

const Footer = () => (
	<StyledFooter>
        <div className="footer-meritorical">
			<img src={logo} width='64' height='64' alt="" />
            <p className="copyright">Copyright © 2022 ARTT Token</p>
        </div>
        <div className="footer-legal">
			<nav>
                <span><strong>ARTT Network:</strong></span>
                <ul>
                    <li>
                        <a href="https://buy.artt.network" target="_self" rel="noopener noreferrer">Home</a>
                    </li>
                    <li>
                        <a href="/" target="_self" rel="noopener noreferrer">ARTT Tools</a>
                    </li>
                </ul>
            </nav>
			<nav>
                <span><strong>ARTT Token:</strong></span>
                <ul>
                    <li>
                        <a href="https://buy.artt.network" target="_self" rel="noopener noreferrer">Buy</a>
                    </li>
                    <li>
                        <a href="/lend" target="_self">Lend</a>
                    </li>
                </ul>
            </nav>
            <nav>
                <span><strong>Legal:</strong></span>
                <ul>
                    <li><a href="https://artt.network/privacy-policy" target="_self" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href="https://artt.network/terms-conditions" target="_self" rel="noopener noreferrer">Terms &amp; Conditions</a></li>
                </ul>
            </nav>
        </div>
	</StyledFooter>
);

export default Footer;
