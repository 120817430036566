import { useContractFunction } from '@usedapp/core';
import { utils } from 'ethers';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ARTTLending from '../../abi/ArtTokenLending.json';
import arttToken from '../../assets/images/logo-artt-token-256x256.png';
import { useContract } from '../../hooks/useContract';
import { renderDate } from '../../utils';
import { CONTRACTS } from '../../utils/constants';
import { ButtonOutlineBlock } from '../base/Button';
import { Col, Row } from './StakeListItem.styled';

const STAKING_ADDRESS = CONTRACTS.BSC.STAKING_ADDRESS;
const STAKING_ABI = new utils.Interface(ARTTLending.abi);

const StakeListItem = ({ index, amount, addedDate, endDate, pendingReward, withdrawalDate, pendingForWithdrawal }) => {
	const staking = useContract(STAKING_ADDRESS, STAKING_ABI);

	const { send: applySend, state: applyState, resetState: applyResetState } = useContractFunction(
		staking,
		'applyToWithdrawLentArtt',
		{ transactionName: 'Apply to withdraw lent ARTT' }
	);

	const { send: withdrawSend, state: withdrawState, resetState: withdrawResetState } = useContractFunction(
		staking,
		'withdrawLentArtt',
		{ transactionName: 'Withdraw lent ARTT' }
	);

	const [ disabled, setDisabled ] = useState(false);
	const currentDateTS = Number.parseInt(String(Date.now() / 1000));
	const endDateTS = endDate && Number.parseInt(endDate);

	useEffect(() => {
		if ( applyState.status !== 'Mining' ) {
			setDisabled(false);
		}

		if ( applyState.status === 'Exception' ) {
			setDisabled(true);
			toast.error(`${ applyState.errorMessage }`);
			applyResetState();
		}

		if ( applyState.status === 'Success' ) {
			toast.success(`Apply to withdraw staked ARTT. txHash: ${ applyState.receipt.transactionHash }`);
		}
	}, [ applyState, applyResetState ]);

	useEffect(() => {
		if ( withdrawState.status !== 'Mining' ) {
			setDisabled(false);
		}

		if ( withdrawState.status === 'Exception' ) {
			setDisabled(true);
			toast.error(`${ withdrawState.errorMessage }`);
			withdrawResetState();
		}
		if ( withdrawState.status === 'Success' ) {
			toast.success(`Apply to withdraw staked ARTT. txHash: ${ withdrawState.receipt.transactionHash }`);
		}
	}, [ withdrawState, withdrawResetState ]);

	const handleWithdraw = async () => {
		await withdrawSend(index);
	};

	const handleApplyToWithdraw = async () => {
		await applySend(index);
	};

	const renderButton = () => {
		if ( pendingForWithdrawal ) {
			return <ButtonOutlineBlock disabled={ disabled } onClick={ handleWithdraw } >Withdraw</ButtonOutlineBlock >;
		}

		if ( endDateTS < currentDateTS ) {
			return (
				<ButtonOutlineBlock disabled={ disabled } onClick={ handleApplyToWithdraw } >Apply</ButtonOutlineBlock >
			);
		}

		return <ButtonOutlineBlock disabled={ true } >Apply</ButtonOutlineBlock >;
	};

	return (
		<Row >
			<Col maxWidth='4.25rem' ><img src={ arttToken } alt='staked asset logo' /></Col >
			<Col maxWidth='15%' >{ amount }</Col >
			<Col maxWidth='20%' >{ renderDate(addedDate && addedDate.toString()) }</Col >
			<Col maxWidth='20%' >{ renderDate(endDate && endDate.toString()) }</Col >
			<Col maxWidth='20%' >{ renderDate(withdrawalDate && withdrawalDate.toString()) }</Col >
			<Col maxWidth='10%' >{ pendingReward }</Col >
			<Col maxWidth='12%' >{ renderButton() }</Col >
		</Row >
	);
};

export default StakeListItem;
