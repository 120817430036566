import styled from 'styled-components';

export const StyledP = styled.p`
  color: ${ ({ theme }) => theme.color };
  font-size: .875rem;
  font-weight: 600;
  margin-bottom: 0;
  text-align: start;
  word-break: break-word;
  background: -o-linear-gradient(350deg, rgba(119, 0, 255, 0.2), rgba(243, 255, 80, 0.3));
  background: linear-gradient(100deg, rgba(119, 0, 255, 0.2), rgba(243, 255, 80, 0.3))
`;