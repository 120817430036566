import { createGlobalStyle } from 'styled-components';

import mobilumBg from '../assets/images/mobilum-bg.png';

export const Global = createGlobalStyle`
/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/

/*
  Anders Noren CSS Reset used in WordPress Chaplin Theme
  https://andersnoren.se/
*/

*, *::before, *::after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  
  body {
    line-height: 1.5;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

  button {
    cursor: pointer;
    background-color: transparent;
  }

  a, path {
  -webkit-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
  color: unset;
  }

  blockquote, q {
    /* no quotes */
    quotes: none;
  }

  blockquote:before,
  blockquote:after {
  content: "";
  }

  table {
    /* no spacing between cells*/
    border-spacing: 0;
    /*borders are collapsed - adjacent table cells share borders */
    border-collapse: collapse;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  select, progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input, button, textarea, select {
    font: inherit;
  }

  input[type=text],
  input[type=button] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
            border-radius: 0;
  }

  svg {
    fill: currentColor;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #root, #__next {
    isolation: isolate;
  }

  @media ( prefers-reduced-motion: reduce ) {
  * {
    -webkit-animation-duration: 0s !important;
            animation-duration: 0s !important;
    -webkit-transition-duration: 0s !important;
         -o-transition-duration: 0s !important;
            transition-duration: 0s !important;
  }
  }
  
  /* --------------------------------------------------------------------------------------------- */	
  /*	1. Document Setup
  /* --------------------------------------------------------------------------------------------- */
  
  :root {
    --main-bg-color: #e5e7f1;
    --main-font-color: rgb(20,20,20);
    --main-heading-color: rgb(61, 85, 114);
  }
  
  body {
    font-family: Rubik, -apple-system, BlinkMacSystemFont, Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    color: var(--main-font-color);
    font-size: 100%;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    background-color: ${ ({ theme }) => theme.body.backgroundColor };
    background-image: url(${ ({ theme }) => theme.background && mobilumBg });
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
  }

  /* --------------------------------------------------------------------------------------------- */
  /*	2. Element Base
  /* --------------------------------------------------------------------------------------------- */
  
  /* Media ------------------------------------- */
  
  figure {
  margin: 0;
  }
  
  iframe {
  border: none;
  display: block;
  max-width: 100%;
  }
  
  svg,
  img,
  embed,
  object {
  display: block;
  height: auto;
  max-width: 100%;
  }
  
  figcaption {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 1.5rem;
  }

  .card {
    background-color: #fbfbfb;
    padding: 1rem;
    width: 33%;
    max-width: 26rem;
    min-width: 20rem;
    -webkit-border-top-left-radius: 14px;
            border-top-left-radius: 14px;
    -webkit-border-bottom-right-radius: 14px;
            border-bottom-right-radius: 14px;
    -webkit-border-top-right-radius: 5px;
            border-top-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
            border-bottom-left-radius: 5px;
    border: 3px solid rgb(194, 206, 223);
    color: var(--main-heading-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  

  .card p,
  .card h2 {
    text-align: center;
  }
  
  /* Inputs ------------------------------------ */
  
  fieldset {
  border: none;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  max-width: 80ch;
  width: 100%;
  }
  
  fieldset > *:first-child { margin-top: 0; }
  fieldset > *:last-child { margin-bottom: 0; }

.button,
.button:active {
display: inline-block;
background: -o-linear-gradient(190deg, rgb(81, 154, 250), rgb(80, 255, 240));
background: linear-gradient(260deg, rgb(81, 154, 250), rgb(80, 255, 240));
padding: 0.9rem 2rem 0.9rem 2rem;
-webkit-border-top-left-radius: 14px;
        border-top-left-radius: 14px;
-webkit-border-bottom-right-radius: 14px;
        border-bottom-right-radius: 14px;
-webkit-border-top-right-radius: 5px;
        border-top-right-radius: 5px;
-webkit-border-bottom-left-radius: 5px;
        border-bottom-left-radius: 5px;
border: none;
margin-top: 1rem;
margin-bottom: 1rem;
font-weight: 600;
color: black;
font-size: 1.2rem;
text-decoration: none;
transition: none;
-webkit-box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 2px 0px;
        box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 2px 0px;
}

.button:hover {
background: -o-linear-gradient(190deg, rgb(0, 0, 0), rgb(0, 0, 0));
background: linear-gradient(260deg, rgb(0, 0, 0), rgb(0, 0, 0));
color: white;
-webkit-box-shadow: rgb(119, 0, 255) -1px 2px 2px 2px, rgb(243, 255, 80) 2px -3px 2px 2px;
        box-shadow: rgb(119, 0, 255) -1px 2px 2px 2px, rgb(243, 255, 80) 2px -3px 2px 2px;
}

.button:focus {
-webkit-box-shadow: rgb(119, 0, 255) -1px 2px 4px 4px, rgb(243, 255, 80) 1px -2px 4px 4px;
        box-shadow: rgb(119, 0, 255) -1px 2px 4px 4px, rgb(243, 255, 80) 1px -2px 4px 4px;
}

.button.disabled,
.button.disabled:hover {
  background: -o-linear-gradient(190deg, rgb(114, 114, 114), rgb(199, 199, 199));
  background: linear-gradient(260deg, rgb(114, 114, 114), rgb(199, 199, 199));
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #e5e7f1;
}

  /* --------------------------------------------------------------------------------------------- */	
  /*	2. Typography
  /* --------------------------------------------------------------------------------------------- */
  
  .page h1 {
  font-size: 3.4rem;
  font-weight: 700;
  letter-spacing: 0.06rem;
  margin-bottom: 1rem;
  line-height: 120%;
  color: var(--main-heading-color);
  }
  
  .page h2 {
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: 0.06rem;
  margin-bottom: 1rem;
  line-height: 120%;
  color: var(--main-heading-color);
  }
  
  .page p {
  font-size: 1rem;
  }
  
  .page p strong,
  span strong {
  background: -o-linear-gradient(350deg, rgba(119, 0, 255, 0.2), rgba(243, 255, 80, 0.3));
  background: linear-gradient(100deg, rgba(119, 0, 255, 0.2), rgba(243, 255, 80, 0.3));
  }

  .page footer nav span strong {
    font-weight: 700;
  }
  
  .page p.subtitle strong {
    font-size: 1.6rem;
  }

  .page .buy-widget .error-message {
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 0.4rem;
  }
  
  .page b {
  font-weight: 500;
  }

  nav ul {
    padding: 0;
  }
  
  nav li {
    list-style-type: none;
  }

  /* App */

  .App {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
  }

  .App-header {
    align-items: center;
    background-color: #f1f1f1;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    min-height: 100vh;
  }

  .App-link {
    color: #09d3ac;
  }

  .page__container {
    min-height: 90vh;
  }

  .muted {
    color: #597291;
  }

  .theme_toggle {
    cursor: pointer;
    color: ${ ({ theme }) => theme.toggleThemeBtn.color };
  }

  .wallet_address {
    cursor: pointer;
  }

  /* SCROLLBAR*/
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    ${ ({ theme }) => theme.backgroundColor };
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(3, 228, 134);
    background: -moz-linear-gradient(180deg, rgba(3, 228, 134, 1) 0%, rgba(0, 246, 155, 1) 20%, rgba(0, 242, 170, 1) 40%, rgba(87, 222, 213, 1) 60%, rgba(0, 179, 246, 1) 80%, rgba(0, 169, 255, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(3, 228, 134, 1) 0%, rgba(0, 246, 155, 1) 20%, rgba(0, 242, 170, 1) 40%, rgba(87, 222, 213, 1) 60%, rgba(0, 179, 246, 1) 80%, rgba(0, 169, 255, 1) 100%);
    background: linear-gradient(180deg, rgba(3, 228, 134, 1) 0%, rgba(0, 246, 155, 1) 20%, rgba(0, 242, 170, 1) 40%, rgba(87, 222, 213, 1) 60%, rgba(0, 179, 246, 1) 80%, rgba(0, 169, 255, 1) 100%);
    border-radius: 10px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#03e486", endColorstr="#00a9ff", GradientType=1);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #339CF5;
  }

  .widget--hidden {
    display: none;
  }

  @media (max-width: 1128px) {
    div .staking, div .approve {
      width: 100%;
    }

    .footer {
      margin: 2rem auto 0 !important;
    }
  }

  @media (max-width: 500px) {
    div .menu {
      flex: 1 1 auto;
      margin: 0;
    }
  }

  @media (max-width: 1248px) {
    main {
      padding: 0 2rem;
    }
  }

/* Header */
.artt-tools header > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 1rem 2rem 1rem 2rem;
}

.artt-tools header .header-left,
.artt-tools header .header-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.artt-tools header .header-left .logo {
  width: 150px;
}

.artt-tools header .header-left .menu nav ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 2rem;
  padding: 0;
}

header nav.main-menu li,
.artt-tools header .header-left .menu nav ul li {
  padding: 0 1rem;
}

header nav.main-menu li a,
.artt-tools header .header-left .menu nav ul li a {
  text-decoration: none;
  font-weight: 500;
  padding: 4px;
  -webkit-border-bottom-left-radius: 2px;
          border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 6px;
          border-bottom-right-radius: 6px;
  -webkit-border-top-right-radius: 2px;
          border-top-right-radius: 2px;
  -webkit-border-top-left-radius: 6px;
          border-top-left-radius: 6px;
  }

header nav.main-menu li a:hover,
header nav.main-menu li a.current-menu-item,
.artt-tools header .header-left .menu nav ul li a:hover,
.artt-tools header .header-left .menu nav ul li a.current-menu-item {
  color: white;
  background-color: var(--main-heading-color);
}

.artt-tools header .header-left .menu nav ul li a.active {
  color: white;
  background-color: var(--main-heading-color);
}

.artt-tools header .header-right svg {
  margin-bottom: 0;
}

@media screen and ( max-width: 700px ) {
  .artt-tools header .header-right svg {
    margin-bottom: 1rem;
  }
}

.artt-tools header .wallet {
  justify-content: center;
}

.artt-tools header .wallet .wallet-address {
  padding: 0.9rem;
  border: 3px solid #c2cedf;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.artt-tools header .wallet .wallet-amount {
  padding: 0.9rem;
  border: 3px solid #c2cedf;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

@media screen and ( max-width: 1200px ) {
  .artt-tools header > div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .artt-tools header .header-left {
    padding-bottom: 1.2rem;
    border-bottom: 3px solid var(--main-bg-color)
  }
  .artt-tools header .header-right {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and ( max-width: 660px ) {
  .artt-tools header .header-left,
  .artt-tools header .header-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  }

  .artt-tools header .header-left .menu nav ul {
    margin: 0 auto;
  }

  .artt-tools header .header-left .menu nav ul li:first-child {
    padding-left: 0;
  }

  .artt-tools header .header-left .menu nav ul li:last-child {
    padding-right: 0;
  }

  .artt-tools header .header-left .logo {
  margin-bottom: 1rem;
  }

  .artt-tools header .image.header-right svg {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

/* Footer */

footer {
  padding: 2rem;
  background-color: #ececec;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap-reverse;
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  }
  
  .footer-legal nav {
    margin-bottom: 1rem;
  }

.footer-meritorical,
.footer-legal {
width: 50%;
}

.footer-meritorical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.footer-legal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
}

@media screen and ( max-width: 960px ) {
  .footer-meritorical,
  .footer-legal {
  width: 100%;
  }
}

.footer-legal nav {
  text-align: end;
  margin-left: 1rem;
  min-width: 12rem;
}

.footer-legal nav ul {
  padding: 0;
}

.footer-legal nav li {
text-align: end;
}

.footer-legal nav li a {
display: inline-block;
font-size: 1rem;
font-weight: 400;
text-decoration: none;
padding: 4px;
-webkit-border-bottom-left-radius: 2px;
        border-bottom-left-radius: 2px;
-webkit-border-bottom-right-radius: 6px;
        border-bottom-right-radius: 6px;
-webkit-border-top-right-radius: 2px;
        border-top-right-radius: 2px;
-webkit-border-top-left-radius: 6px;
        border-top-left-radius: 6px;
}

.footer-legal nav li a:hover {
  color: white;
  background-color: var(--main-heading-color);
}

.copyright {
margin-top: 2rem;
margin-bottom: 0;
font-size: 1rem;
}

/* Swap */

.swap-form {
  padding: 1rem;
  border: 3px solid #c2cedf;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.swap-form .swap-form-icon {
  margin-top: 1rem;
}
`;