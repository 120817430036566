import { commify, formatUnits } from '@ethersproject/units';
import { useCoingeckoPrice } from '@usedapp/coingecko';
import { useEthers, useToken, useTokenBalance } from '@usedapp/core';
import React from 'react';
import DescriptionDetails from '../components/base/DescriptionDetails';
import DescriptionTerm from '../components/base/DescriptionTerm';
import FlexContainer from '../components/base/FlexContainer/FlexContainer';
import StakeForm from '../components/StakeForm';
import StakeList from '../components/StakeList';
import { useTotalHolders } from '../hooks/useTotalHolders';
import { useTotalPendingRewards } from '../hooks/useTotalPendingRewards';
import { useTotalRewardsPool } from '../hooks/useTotalRewardsPool';
import { useTotalStakedByAddress } from '../hooks/useTotalStakedByAddress';
import { useTotalStakedPool } from '../hooks/useTotalStakedPool';
import { calculateTVL } from '../utils';
import { CONTRACTS } from '../utils/constants';
import {useStakedBalancesCount} from "../hooks/useStakedBalancesCount";

const TOKEN_ADDRESS = CONTRACTS.BSC.TOKEN_ADDRESS;

const Lend = () => {
	const { account } = useEthers();
	const arttInfo = useToken(TOKEN_ADDRESS);
	const mbmBalance = useTokenBalance(TOKEN_ADDRESS, account);
	const totalRewardsPool = useTotalRewardsPool();
	const totalPendingRewards = useTotalPendingRewards(account);
	const totalStakedPool = useTotalStakedPool();
	const totalStakedByUser = useTotalStakedByAddress(account);
	const totalHolders = useStakedBalancesCount();
	const arttMarketPrice = useCoingeckoPrice('mbm', 'usd');
	const tvl = calculateTVL(totalStakedPool, arttMarketPrice);

	return (
		<main className='page__container' >
			<FlexContainer rowGap='1rem' maxWidth='86rem' width='100%'
			               margin='2rem auto' justify='flex-start' direction='column' minHeight='77vh'
			               minWidth='15rem' backgroundColor='unset'
			>
				<FlexContainer justify='space-between' align='stretch' gap='1rem' width='100%'
				               backgroundColor='transparent'
				>
					<FlexContainer rowGap='1rem' align='stretch' minHeight='auto' justify='flex-start' minWidth='15rem'
					               width='20%' direction='column' padding='1rem 2rem' flex='1 1 auto'
					               overflow='hidden' shadow border
					>
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start'
						               minHeight='3.125rem' maxHeight='9.25rem'
						>
							<DescriptionTerm className='muted' >Wallet Balance:</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{
									mbmBalance && commify(formatUnits(mbmBalance, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
								}
							</DescriptionDetails >
						</FlexContainer >
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start' >
							<DescriptionTerm className='muted' >Lent Balance:</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{
									totalStakedByUser > 0
										? commify(totalStakedByUser) + ' ' + arttInfo?.symbol
										: arttInfo && commify(formatUnits(0, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
								}
							</DescriptionDetails >
						</FlexContainer >
					</FlexContainer >
					<FlexContainer rowGap='1rem' align='stretch' minHeight='auto' justify='flex-start' minWidth='15rem'
					               width='20%' direction='column' padding='1rem 2rem' flex='1 1 auto'
					               overflow='hidden' shadow border
					>
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start'
						               minHeight='3.125rem' maxHeight='9.25rem'
						>
							<DescriptionTerm className='muted' >Total Rewards Pool:</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{
									totalRewardsPool
										?
										commify(formatUnits(totalRewardsPool, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
										:
										arttInfo && commify(formatUnits(0, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
								}
							</DescriptionDetails >
						</FlexContainer >
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start' >
							<DescriptionTerm className='muted' >Total Pending Rewards:</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{
									totalPendingRewards
										? commify(totalPendingRewards)
										+ ' ' + arttInfo?.symbol
										: arttInfo && commify(formatUnits(0, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
								}
							</DescriptionDetails >
						</FlexContainer >
					</FlexContainer >
					<FlexContainer rowGap='1rem' align='stretch' minHeight='auto' justify='flex-start' minWidth='15rem'
					               width='20%' direction='column' padding='1rem 2rem' flex='1 1 auto'
					               overflow='hidden' shadow border
					>
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start'
						               minHeight='3.125rem' maxHeight='9.25rem'
						>
							<DescriptionTerm className='muted' >Total Lent:</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{
									totalStakedPool
										?
										commify(formatUnits(totalStakedPool, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
										:
										arttInfo && commify(formatUnits(0, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
								}
							</DescriptionDetails >
						</FlexContainer >
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start' >
							<DescriptionTerm className='muted' >Total Supply:</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{
									arttInfo?.totalSupply
										?
										commify(formatUnits(arttInfo?.totalSupply, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
										:
										arttInfo && commify(formatUnits(0, arttInfo?.decimals)) + ' ' + arttInfo?.symbol
								}
							</DescriptionDetails >
						</FlexContainer >
					</FlexContainer >
					<FlexContainer rowGap='1rem' align='stretch' minHeight='auto' justify='flex-start' minWidth='15rem'
					               width='20%' direction='column' padding='1rem 2rem' flex='1 1 auto'
					               overflow='hidden' shadow border
					>
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start' >
							<DescriptionTerm className='muted' >Total Lents:</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{
									+totalHolders
										? commify(+totalHolders)
										: ''
								}
							</DescriptionDetails >
						</FlexContainer >
						<FlexContainer rowGap='5px' direction='column' flexGrow='1' align='flex-start'
						               minHeight='3.125rem' maxHeight='9.25rem'
						>
							<DescriptionTerm className='muted' >Total Value Locked (TVL):</DescriptionTerm >
							<DescriptionDetails className='digits' >
								{ tvl && '$' + commify(formatUnits(tvl)) }
							</DescriptionDetails >
						</FlexContainer >
					</FlexContainer >
				</FlexContainer >
				<br/>
				<br/>
				<br/>
				<br/>

				<FlexContainer backgroundColor='transparent'>
					<br/>
					<br/>
					<br/>
					<big > ARTT LENDING COMING SOON</big>
					<br/>
					<br/>
					<br/>
				</FlexContainer>
				{/*<FlexContainer minHeight='57vh' height='100%' width='100%' backgroundColor='transparent' gap='1rem' align='stretch'*/}
				{/*>*/}
				{/*	<StakeForm />*/}
				{/*	<StakeList />*/}
				{/*</FlexContainer >*/}
			</FlexContainer >
		</main >
	);
};

export default Lend;
