import { useContractCalls } from '@usedapp/core';
import { utils } from 'ethers';
import ARTTLending from '../abi/ArtTokenLending.json';
import { CONTRACTS } from '../utils/constants';
import { useStakedIndexesPerAddressCount } from './useStakedIndexesPerAddressCount';

const STAKING_ABI = new utils.Interface(ARTTLending.abi);
const STAKING_ADDRESS = CONTRACTS.BSC.STAKING_ADDRESS;

export function useStakedBalancesByAddress(address) {
	const count = useStakedIndexesPerAddressCount(address);

	const callsIndexes = [];
	const callsBalances = [];

	for ( let i = 0; i < count; i++ ) {
		callsIndexes.push(
			address &&
			{
				abi: STAKING_ABI,
				address: STAKING_ADDRESS,
				method: 'lentIndexesPerAddress',
				args: [ address, String(i) ],
			}
		);
	}

	const stakedIndexes = useContractCalls(callsIndexes);

	for ( let i = 0; i < stakedIndexes.length; i++ ) {
		callsBalances.push(
			address &&
			stakedIndexes[ i ] &&
			{
				abi: STAKING_ABI,
				address: STAKING_ADDRESS,
				method: 'lentBalances',
				args: [ String(stakedIndexes[ i ]) ],
			}
		);
	}

	return useContractCalls(callsBalances);
}
