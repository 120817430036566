import { Widget } from '@maticnetwork/wallet-widget';
import {BSC, DAppProvider} from '@usedapp/core';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { POLYGON_WIDGET_CONFIG } from './utils/constants';

const widget = new Widget(POLYGON_WIDGET_CONFIG);

const config = {
	networks: [ BSC ]
};

ReactDOM.render(
	<React.StrictMode >
		<DAppProvider config={ config } >
			<App widget={ widget } />
		</DAppProvider >
	</React.StrictMode >,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
