import React from 'react';
import {
	A,
	StyledButton,
	StyledButtonBlock,
	StyledConnectButton,
	StyledLink,
	StyledOutlineButton,
	StyledOutlineButtonBlock,
	StyledPrimaryButton,
	StyledWarnButton
} from './Button.styled';

const handleLinkWrapping = (Component, props) => {
	const { href, to, target, children, disabled, isLoading, ...rest } = props;
	const button = (
		<Component disabled={ disabled || isLoading } { ...rest }>
			{ children }
		</Component >
	);

	if ( to ) return <StyledLink to={ to } >{ button }</StyledLink >;

	if ( href ) return (
		<A
			href={ href }
			target={ target || '_blank' }
			rel={ !target ? 'noopener noreferrer' : undefined }
		>
			{ button }StyledFullWidthButton
		</A >
	);

	return button;
};


export const Btn = (props) => handleLinkWrapping(StyledButton, props);
export const BtnBlock = (props) => handleLinkWrapping(StyledButtonBlock, props);
export const BtnWarning = (props) => handleLinkWrapping(StyledWarnButton, props);
export const BtnPrimary = (props) => handleLinkWrapping(StyledPrimaryButton, props);
export const BtnOutline = (props) => handleLinkWrapping(StyledOutlineButton, props);
export const BtnConnect = (props) => handleLinkWrapping(StyledConnectButton, props);
export const BtnOutlineBlock = (props) => handleLinkWrapping(StyledOutlineButtonBlock, props);

