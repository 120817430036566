import { formatUnits } from '@ethersproject/units';
import { useStakedBalancesByAddress } from './useStakedBalancesByAddress';

export function useTotalStakedByAddress(address) {
	const stakedBalances = useStakedBalancesByAddress(address);
	let total = 0;

	stakedBalances.forEach(balance => {
		total += balance
			? Number(formatUnits(balance?.amount))
			: 0;
	});

	return total;
}