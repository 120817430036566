export const CONNECT_TEXT = 'Connect';
export const CONNECTED_TEXT = 'Disconnect';
export const ERR_SWITCH_NETWORK = 'Error switching network';
export const MAX_INTEGER = '115792089237316195423570985008687907853269984665640564039457';

export const TX_NAME = {
	APPROVE_TOKEN: 'Approve spending ARTT.',
	STAKE_TOKEN: 'LendListItem ARTT.',
};

export const EXCEPTION_MSG = {
	NONE: '',
	NOT_ENOUGH_BALANCE: 'Not enough balance.',
	INVALID_PERIOD: 'Allowed periods: 30, 90, 360.',
};

export const SUCCESS_MSG = {
	NONE: '',
	APPROVE_TOKEN: 'Approved spending ARTT.',
	STAKE_TOKEN: 'Lent ARTT. txHash:',
};

export const CONTRACTS = {
	BSC: {
		TOKEN_ADDRESS: '0x9158E70119d661BA0cAEB2b392EDd9565CaC82b7',
		STAKING_ADDRESS: '0x19b0E9B5d3dDf8177B31e7239aEa03461F13e3bF',
	}
};

export const STAKING_MARGIN = {
	LOW: '0',
	MEDIUM: '1',
	HIGH: '2'
};

export const WELCOME_TITLE = 'ARTT Tools';

export const BRIDGE_TITLE = 'Bridge';
export const BRIDGE_TEXT = 'Bridge your ARTT between BSC and ETH mainnet';

export const STAKE_TITLE = 'Lend';
export const STAKE_TEXT = 'Lend your ARTT to earn rewards';

export const SWAP_TITLE = 'Swap';
export const SWAP_TEXT = 'Swap your ARTT for ETH';

export const POLYGON_WIDGET_CONFIG = {
	appName: 'MBM_Polygon_Bridge',
	autoShowTime: 0,
	position: 'center',
	width: 1050,
	height: 580,
	network: 'testnet',
	closable: true,
};
