import React from 'react';

import FlexContainer from '../base/FlexContainer/FlexContainer';
import { StyledHeader } from './Header.styled';
import Menu from './Menu';
import WalletConnect from './WalletConnect';

const Header = ({ toggleTheme, theme }) => (
	<StyledHeader >
		<FlexContainer padding='10px 0' justify='space-between' maxWidth='86.25rem' width='100%' margin='0 auto' >
			<Menu />
			<WalletConnect toggleTheme={ toggleTheme } theme={ theme } />
		</FlexContainer >
	</StyledHeader >
);

export default Header;
