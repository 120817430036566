export const lightTheme = {
	background: 'lightTheme',
	backgroundColor: '#F9FAFB',
	boxShadow: '0 14px 6px -10px rgb(0 0 0 / 30%)',
	border: '1px solid rgba(0, 0, 0, 0.1)',
	color: '#575c66',
	body: {
		backgroundColor: '#EFF1F3',
	},
	input: {
		backgroundColor: '#EFF1F3'
	},
	label: {
		backgroundColor: '#EFF1F3',
		color: '#575c66',
	},
	button: {
		boxShadow: '0 4px 2px #bbb'
	},
	card: {
		color: '#121212',
	},
	toggleThemeBtn: {
		color: '#575c66'
	}
};

export const darkTheme = {
	background: null,
	backgroundColor: '#333333',
	boxShadow: '0 14px 6px -10px rgb(0 0 0 / 100%)',
	border: '1px solid rgba(255, 255, 255, 0.1)',
	color: 'white',
	body: {
		backgroundColor: '#121212',
	},
	input: {
		backgroundColor: '#2b2b2b'
	},
	label: {
		backgroundColor: '#2b2b2b',
		color: '#DFDFDF',
	},
	button: {
		boxShadow: '0 4px 2px #464646'
	},
	card: {
		color: '#FFF',
	},
	toggleThemeBtn: {
		color: '#9F9F9F'
	}
};