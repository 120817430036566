/* global BigInt */
import { STAKING_MARGIN } from './constants';

export function digitGrouping(value) {
	return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export function digitGroupingWithDecimals(value, decimals) {
	let temp = Number.parseFloat(value).toFixed(decimals);
	return temp.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export const appendDays = (date, days) => {
	return date + days * 86400;
};

export const toTimestamp = endDate => Date.parse(endDate);

export const renderDate = date => {
	if ( date > 0 ) {
		const dateObj = new Date(date * 1000);
		const utcString = dateObj.toUTCString();

		return utcString.slice(-22, -4);
	}

	return '-- -- --:--:--';
};

export const calculateTVL = (totalStakedPool, price) => {
	// TODO: Impl. once price is defined.
	// return totalStakedPool && price && BigInt(totalStakedPool * price);
	return totalStakedPool && BigInt(totalStakedPool * 0.04);
};

export const calculateStakingMargin = (amount) => {
	switch ( true ) {
		case amount >= 5000000:
			return STAKING_MARGIN.HIGH;
		case amount >= 1000000 && amount < 5000000:
			return STAKING_MARGIN.MEDIUM;
		default:
			return STAKING_MARGIN.LOW;
	}
};

export const calculateStakingReward = async (amount, startDate, endDate, stakingMargin, stakingContract) => {
	return await stakingContract.calculateLendingReward(
		amount,
		startDate,
		endDate,
		stakingMargin
	);
};

export const calculateApy = (apy) => {
	return apy && Number.parseInt(apy) / 100;
};

export const copyToClipBoard = (account) => {
	return navigator.clipboard.writeText(account);
};
