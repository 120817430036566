import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Brand = styled.div`
  & > img {
    width: 100%;
  }
`;

export const StyledNavLink = styled(NavLink)`
  p {
    -webkit-user-select: none;
    color: ${ ({ theme }) => theme.color };
    cursor: pointer;
    flex-basis: 100%;
    font-size: 1rem;
    font-weight: 600;
    height: 100%;
    letter-spacing: 3px;
    position: relative;
    text-transform: uppercase;
    user-select: none;
  }

  &:hover > p {
    background: rgb(3, 228, 134);
    background: -moz-linear-gradient(90deg, rgba(3, 228, 134, 1) 0%, rgba(0, 246, 155, 1) 20%, rgba(0, 242, 170, 1) 40%, rgba(87, 222, 213, 1) 60%, rgba(0, 179, 246, 1) 80%, rgba(0, 169, 255, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(3, 228, 134, 1) 0%, rgba(0, 246, 155, 1) 20%, rgba(0, 242, 170, 1) 40%, rgba(87, 222, 213, 1) 60%, rgba(0, 179, 246, 1) 80%, rgba(0, 169, 255, 1) 100%);
    background: linear-gradient(90deg, rgba(3, 228, 134, 1) 0%, rgba(0, 246, 155, 1) 20%, rgba(0, 242, 170, 1) 40%, rgba(87, 222, 213, 1) 60%, rgba(0, 179, 246, 1) 80%, rgba(0, 169, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#03e486",endColorstr="#00a9ff",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;