import { formatEther } from '@ethersproject/units';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Jazzicon from '@metamask/jazzicon';
import { shortenAddress, useEtherBalance, useEthers, useLookupAddress } from '@usedapp/core';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import ethereumLogo from '../../../assets/images/ethereum.svg';
import metamask from '../../../assets/images/metamask.svg';
import { copyToClipBoard } from '../../../utils';
import { CONNECT_TEXT, CONNECTED_TEXT, ERR_SWITCH_NETWORK } from '../../../utils/constants';
import { BtnConnect } from '../../base/Button/Button';
import FlexContainer from '../../base/FlexContainer/FlexContainer';
import { StyledIcon, StyledIdenticon, StyledImg } from './WalletConnect.styled';

const networks = {
	bsc: {
		chainId: `0x${ Number(56).toString(16) }`,
		chainName: 'Bsc Mainnet',
		nativeCurrency: {
			name: 'BNB',
			symbol: 'BNB',
			decimals: 18
		},
		rpcUrls: [ 'https://bsc-dataseed1.binance.org/' ],
		blockExplorerUrls: [ 'https://bscscan.com/' ]
	}
};

const WalletConnect = ({toggleTheme, theme}) => {
    const [count, setCount] = useState(0);
    const {activateBrowserWallet, account, active, deactivate, error} = useEthers();
    const etherBalance = useEtherBalance(account);
    const ens = useLookupAddress();
    const ref = useRef(HTMLDivElement);
    const {chainId} = useEthers()
    const [url, setUrl] = useState(window.location.pathname);

    useEffect(() => {
        if (!window.ethereum) toast.error('Metamask wallet not found! Please install.');

        if (error) {
            handleNetworkSwitch('bsc').then();
            toast.error(error.message);
        }

        if (count > 1 && active) toast.success('Metamask connected!');

        if (count > 1 && !active && !error) toast.success('Metamask disconnected!');

        if (account && ref.current) {
            ref.current.innerHTML = '';
            ref.current.appendChild(Jazzicon(16, parseInt(account.slice(2, 10), 16)));
        }

        setCount(count => count + 1);

        window.ethereum.on('chainChanged', networkChanged);

        return () => {
            window.ethereum.removeListener('chainChanged', networkChanged);
        };
        // eslint-disable-next-line
    }, [error, account]);

    useEffect( () => {

        if (url && chainId && !error) {
            if (url !== '/bridge' && url !== '/' && chainId !== 56) {
                toast.error('Unsupported chain id! Supported chain Id is 56');
                handleNetworkSwitch('bsc').then();
            }
        }
        // eslint-disable-next-line
    }, [url, chainId]);

    useEffect(() => {
        if (url !== window.location.pathname) {
            setUrl(window.location.pathname);
        }
    });

    const changeNetwork = async ({networkName}) => {
        try {
            setTimeout(async () => {
                if (!window.ethereum) throw new Error('No crypto wallet found');
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            ...networks[networkName]
                        }
                    ]
                }).then(()=>{
                    toast.success('Network changed!');
                });
            }, 2000);
        } catch (err) {
            toast.error(ERR_SWITCH_NETWORK);
        }
    };

    const handleNetworkSwitch = async (networkName) => {
        await changeNetwork({networkName});
    };

	const networkChanged = (chainId) => {
		console.log({ chainId });
	};

	const handleConnectWallet = async () => {
		activateBrowserWallet();
	};

	const handleDisconnectWallet = () => {
		deactivate();
	};

	return (
		<FlexContainer className='header-right' gap='1rem' >
			<FontAwesomeIcon className='theme_toggle' onClick={ () => toggleTheme(theme) }
			                 icon={ theme === 'light' ? faMoon : faSun } size='lg'
			/>
			<FlexContainer flex='1 1 auto' justify='center' minWidth='9rem' height='auto' radius='10px' margin='0 1rem 0 1rem'>
				{
					active ?
						(
							<>
								{
									account &&
									<FlexContainer className='wallet_address' flex='1 0 auto' justify='center'
									               padding='0 0.5rem' minWidth='9rem' height='32px' radius='10px' border
									               onClick={ () => copyToClipBoard(account) } >
										<StyledIdenticon ref={ ref } />
										<p data-tip='Copied Address'
										   data-delay-hide='1000' >{ ens ?? shortenAddress(account) }
										</p >
										<ReactTooltip delayHide={ 1000 } event='click'
										              backgroundColor={ theme === 'dark' ? '#FFF' : '#000' }
										              textColor={ theme === 'dark' ? '#000' : '#FFF' }
										              afterShow={ () => ReactTooltip.hide() } />
									</FlexContainer >
								}

								{
									etherBalance &&
									<FlexContainer flex='1 0 auto' justify='center' padding='0 0.5rem' minWidth='9rem'
									               height='32px' radius='10px' border >
										<StyledIcon src={ ethereumLogo } alt={ 'ethereum logo' } />
										<p >
											{ Number.parseFloat(formatEther(etherBalance)).toFixed(4) }
										</p >
									</FlexContainer >
								}

								<BtnConnect border onClick={ () => handleDisconnectWallet() } >
									<StyledImg src={ metamask } alt='network logo' />
									{ CONNECTED_TEXT }
								</BtnConnect >
							</>
						) :
						(
							<BtnConnect border onClick={ handleConnectWallet } >
								<StyledImg src={ metamask } alt='network logo' />
								{ CONNECT_TEXT }
							</BtnConnect >
						)
				}
			</FlexContainer >
		</FlexContainer >
	);
};

export default WalletConnect;
