import styled from 'styled-components';

export const StyledParagraph = styled.p`
  color: ${ ({ theme }) => theme.color };
  font-size: 1rem;
  font-weight: 400;
  height: 2rem;
  line-height: 1.5;
  margin: 0;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
`;