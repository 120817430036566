import styled from 'styled-components';

export const Title = styled.h1`
  color: ${ ({ theme }) => theme.color };
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 auto 2rem auto;
  text-align: start;
  word-break: break-word;
`;

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: flex-start;
  row-gap: 10px;

  @media (max-width: 1128px) {
    row-gap: 1rem;
  }
`;

export const FormError = styled.span`
  background-color: #d934341c;
  border-radius: 10px;
  color: red;
  height: fit-content;
  padding: 1rem 2px;
  width: 100%;

  p {
    text-align: center;
  }
`;