import React, { useEffect } from 'react';
import FlexContainer from '../components/base/FlexContainer/FlexContainer';

// const styles = {
// 	leftBorderRadius: '10px 0 0 10px',
// 	rightBorderRadius: '0 10px 10px 0px'
// };
// const types = [ 'Polygon', 'Binance' ];

const Bridge = ({ widget }) => {
	// const [ active, setActive ] = useState(types[ 0 ]);

	// const handleClick = (type) => {
	// 	setActive(type);
	// };

	// const renderTabIcon = (type) => {
	// 	switch ( type ) {
	// 		case 'Polygon':
	// 			return logoPolygon;
	// 		case 'Binance':
	// 			return logoBinance;
	// 		default:
	// 			console.log(`Error loading image.`);
	// 	}
	// };

	// const renderContent = () => {
	// if ( active === 'Polygon' ) {
	// 	widget.show();
	// }
	//
	// if ( active === 'Binance' ) {
	// 	widget.hide();
	// 	return (
	// 		<FlexContainer maxWidth='86.25rem' height='64vh' width='100%' margin='2rem auto' justify='center'
	// 		               radius='10px'
	// 		>
	// 			<h1 >Binance bridge</h1 >
	// 		</FlexContainer >
	// 	);
	// }
	// };

	useEffect(() => {
		widget.show();
		const elementExists = document.querySelector('.matic-widget.matic-widget--center');
		elementExists && elementExists.classList.remove('widget--hidden');

		return () => {
			elementExists && elementExists.classList.add('widget--hidden');
		};
	}, [ widget ]);

	return (
		<main className='page__container' >
			<FlexContainer rowGap='2rem' maxWidth='86.25rem' width='100%' backgroundColor='unset'
			               margin='2rem auto 0' justify='flex-start' direction='column' minHeight='77vh'
			               minWidth='15rem'
			>
				<FlexContainer maxWidth='86.25rem' width='100%' margin='0 auto' justify='flex-start' 
				               gap='0' backgroundColor='transparent'
				/>
			</FlexContainer >
		</main >
	);
};

export default Bridge;