import React from 'react';
import { StyledDiv } from './FlexContainer.styled';


const FlexContainer = ({ children, ...props }) => (
	<StyledDiv { ...props }>
		{ children }
	</StyledDiv >
);

export default FlexContainer;