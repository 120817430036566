import { formatUnits, parseUnits } from '@ethersproject/units';
import { useContractFunction, useEthers, useToken, useTokenBalance } from '@usedapp/core';
import { utils } from 'ethers';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FixedSupplyToken from '../../abi/FixedSupplyToken.json';
import ARTTLending from '../../abi/ArtTokenLending.json';
import { useContract } from '../../hooks/useContract';
import { appendDays, calculateApy, calculateStakingMargin, calculateStakingReward } from '../../utils';
import { CONTRACTS, EXCEPTION_MSG, SUCCESS_MSG, TX_NAME } from '../../utils/constants';
import { ButtonBlock } from '../base/Button';
import FlexContainer from '../base/FlexContainer/FlexContainer';
import Input from '../base/Input';
import InputGroup from '../base/InputGroup';
import Label from '../base/Label';
import Radio from '../base/Radio';
import Spinner from '../base/Spinner';
import { Form, FormError } from './StakeForm.styled';

const TOKEN_ADDRESS = CONTRACTS.BSC.TOKEN_ADDRESS;
const STAKING_ADDRESS = CONTRACTS.BSC.STAKING_ADDRESS;
const STAKING_ABI = new utils.Interface(ARTTLending.abi);
const ERC20_ABI = new utils.Interface(FixedSupplyToken.abi);

const StakeForm = () => {
	const { account, error } = useEthers();

	const tokenInfo = useToken(TOKEN_ADDRESS);
	const token = useContract(TOKEN_ADDRESS, ERC20_ABI, true);
	const tokenBalance = useTokenBalance(TOKEN_ADDRESS, account);
	const staking = useContract(STAKING_ADDRESS, STAKING_ABI, true);
	// const allowance = useTokenAllowance(TOKEN_ADDRESS, account, STAKING_ADDRESS);

	const { send: sendApprove, state: stateApprove, resetState: resetStateApprove } = useContractFunction(
		token,
		'approve',
		{ transactionName: TX_NAME.APPROVE_TOKEN }
	);

	const { send: sendStake, state: stateStake, resetState: resetStateStake } = useContractFunction(
		staking,
		'lendArtt',
		{ transactionName: TX_NAME.STAKE_TOKEN }
	);

	const [ amount, setAmount ] = useState('');
	const [ period, setPeriod ] = useState('');
	const [ amountValid, setAmountValid ] = useState(false);
	const [ periodValid, setPeriodValid ] = useState(false);
	const [ amountChecked, setAmountChecked ] = useState('');
	const [ periodChecked, setPeriodChecked ] = useState(false);
	const [ apy, setApy ] = useState('');
	const [ estimatedEarnings, setEstimatedEarnings ] = useState('');

	const [ disabled, setDisabled ] = useState(false);
	const [ formErrors, setFormErrors ] = useState({ amount: '', period: '' });

	// TODO: split component update dependencies into two useEffect hooks Approve first Stake second
	useEffect(() => {
		if ( stateApprove.status === 'Success' ) {
			toast.success(`${ SUCCESS_MSG.APPROVE_TOKEN } ${ stateApprove.receipt.transactionHash }`);
		}

		if ( stateApprove.status !== 'Mining' ) {
			setDisabled(false);
			setAmountChecked('');
			setPeriodChecked(false);
			setAmount('');
			setPeriod('');
			setEstimatedEarnings('');
		}

		if ( stateApprove.status === 'Exception' ) {
			setDisabled(true);
			toast.error(`${ stateApprove.errorMessage }`);
			resetStateApprove();
		}

		// todo set dependencies to watch and rerender
	}, [ stateApprove, resetStateApprove ]);

	useEffect(() => {

		if ( stateStake.status !== 'Mining' ) {
			setDisabled(false);
			setAmountChecked('');
			setPeriodChecked(false);
			setAmount('');
			setPeriod('');
			setEstimatedEarnings('');
		}

		if ( stateStake.status === 'Exception' ) {
			setDisabled(true);
			toast.error(`${ stateStake.errorMessage }`);
			resetStateStake();
		}

		if ( stateStake.status === 'Success' ) {
			toast.success(`${ SUCCESS_MSG.STAKE_TOKEN } ${ stateStake.receipt.transactionHash }`);
		}
	}, [ stateStake, resetStateStake ]);

	useEffect(() => {
		(async () => {
			const startDate = Math.trunc(Date.now() / 1000);
			const endDate = appendDays(startDate, period && Number.parseInt(period));
			const stakingMargin = calculateStakingMargin(amount);

			const estRewards = amount && period && await calculateStakingReward(
				amount,
				startDate.toString(),
				endDate.toString(),
				stakingMargin,
				staking
			);

			setApy(calculateApy(estRewards?.apy?.toString()));
			setEstimatedEarnings(estRewards?.reward?.toString());
		})();
	});

	const handleAmountChange = (e) => {
		let value = e.currentTarget.value;

		if ( value > tokenBalance && Number(formatUnits(tokenBalance, tokenInfo?.decimals)) ) {
			setAmountValid(false);
			setFormErrors({ ...formErrors, amount: EXCEPTION_MSG.NOT_ENOUGH_BALANCE });
		} else {
			setAmount(value);
			setAmountValid(true);
			setAmountChecked(value);
			setFormErrors({ ...formErrors, amount: EXCEPTION_MSG.NONE });
		}
	};

	const calculateAmountPercentage = (value) => {
		return Math.trunc(Number(formatUnits(tokenBalance, tokenInfo?.decimals)) * value / 100).toString();
	};

	const handleAmountPercentageChange = (e) => {
		let value = e.currentTarget.value;
		if ( value > Number(formatUnits(tokenBalance, tokenInfo?.decimals)) ) {
			setAmountValid(false);
			setFormErrors({ ...formErrors, amount: EXCEPTION_MSG.NOT_ENOUGH_BALANCE });
		} else {
			setAmount(calculateAmountPercentage(value));
			setAmountValid(true);
			setAmountChecked(value);
			setFormErrors({ ...formErrors, amount: EXCEPTION_MSG.NONE });
		}
	};

	const handlePeriodChange = (e) => {
		let value = e.currentTarget.value;

		if ( value && value !== '30' && value !== '90' && value !== '360' ) {
			setPeriodValid(false);
			setFormErrors({ ...formErrors, period: EXCEPTION_MSG.INVALID_PERIOD });
		} else {
			setPeriod(value);
			setPeriodValid(true);
			setPeriodChecked(true);
			setFormErrors({ ...formErrors, period: EXCEPTION_MSG.NONE });
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setDisabled(true);
		await sendApprove(STAKING_ADDRESS, parseUnits(amount));
		await sendStake(parseUnits(amount), period);
	};

	// const handleApprove = async (e) => {
	// 	e.preventDefault();
	// 	setDisabled(true);
	// 	await sendApprove(STAKING_ADDRESS, parseUnits(amount));
	// };
	//
	// const handleStake = async (e) => {
	// 	e.preventDefault();
	// 	setDisabled(true);
	// 	await sendStake(parseUnits(amount), period);
	// };

	// const renderButton = () => {
	// 	if ( allowance > 0 ) {
	// 		return (
	// 			<ButtonBlock className='spinner' type='submit' margin='2rem 0 0' onClick={ handleStake }
	// 			             disabled={ !account || disabled || !amount || !amountValid || !period || !periodValid }
	// > { stateStake.status === 'Mining' ? <Spinner /> : 'Stake' } </ButtonBlock > ); } else { return ( <ButtonBlock
	// className='spinner' type='submit' margin='2rem 0 0' onClick={ handleApprove } disabled={ !account || disabled ||
	// !amount || !amountValid || !period || !periodValid } > { stateApprove.status === 'Mining' ? <Spinner /> :
	// 'Approve' } </ButtonBlock > ); } };

	const renderFormErrors = () => {
		return (amount && !amountValid) || (period && !periodValid)
			? (
				<FormError >
					<p >{ formErrors.amount }</p >
					<p >{ formErrors.period }</p >
				</FormError >
			)
			: null;
	};

	return (
		<FlexContainer className='staking staking__container' rowGap='2rem' gap='1rem' align='stretch'
		               minHeight='34.875rem' justify='center' width='20.813rem' direction='column'
		               padding='2rem' flex='0 1 auto' shadow border >
			<Form onSubmit={ handleSubmit } >
				<InputGroup >
					<Label bold >Amount:</Label >
					<Input id={ `MBMInput` } type='number' min='0' placeholder='0.00 ARTT' value={ amount }
					       onChange={ handleAmountChange } disabled={ disabled } border />
				</InputGroup >
				<FlexContainer width='100%' >
					<Radio onChange={ handleAmountPercentageChange } labelText='25%' value='25' name='amount'
					       checked={ amountChecked === '25' } disabled={ error } />
					<Radio onChange={ handleAmountPercentageChange } labelText='50%' value='50' name='amount'
					       checked={ amountChecked === '50' } disabled={ error } />
					<Radio onChange={ handleAmountPercentageChange } labelText='75%' value='75' name='amount'
					       checked={ amountChecked === '75' } disabled={ error } />
					<Radio onChange={ handleAmountPercentageChange } labelText='100%' value='100'
					       name='amount' checked={ amountChecked === '100' } disabled={ error } />
				</FlexContainer >
				<InputGroup >
					<Label bold >Period in months:</Label >
					<FlexContainer width='100%' >
						<Radio onChange={ handlePeriodChange } checked={ period === '30' && periodChecked }
						       labelText='1' value='30' name='period' disabled={ error } />
						<Radio onChange={ handlePeriodChange } checked={ period === '90' && periodChecked }
						       labelText='3' value='90' name='period' disabled={ error } />
						<Radio onChange={ handlePeriodChange } checked={ period === '360' && periodChecked }
						       labelText='12' value='360' name='period' disabled={ error } />
					</FlexContainer >
				</InputGroup >
				<InputGroup >
					<Label bold >APY:</Label >
					<Input type='number' readOnly placeholder={ apy && apy + '%' } border />
				</InputGroup >
				<InputGroup >
					<Label bold >Estimated Earnings:</Label >
					<Input type='number' readOnly placeholder={ estimatedEarnings && '~' + estimatedEarnings } border />
				</InputGroup >
				{ renderFormErrors() }
				{/*{ renderButton() }*/ }
				<ButtonBlock className='spinner' type='submit' margin='2rem 0 0'
				             disabled={ !account || disabled || !amount || !amountValid || !period || !periodValid } >
					{ stateApprove.status === 'Mining' || stateStake.status === 'Mining' ? <Spinner /> : 'Lend' }
				</ButtonBlock >
			</Form >
		</FlexContainer >
	);
};

export default StakeForm;
