import React from 'react';
import { Link } from "react-router-dom";
import FlexContainer from '../components/base/FlexContainer';
import { ReactComponent as LendIcon } from '../assets/images/timeline_FILL1_wght400_GRAD0_opsz48.svg';

const Home = () => {
	return (
		<main className='page__container' >
			<FlexContainer justify='center' minHeight='70vh' width='100%' padding='2rem' backgroundColor='unset' >
				<h1 style={{textAlign: "center"}}>ARTT Token Tools</h1>
				<FlexContainer direction='row' justify='center' align='stretch' width='100%' backgroundColor='unset' >
					<div className='card'>
						<div style={{width: "48px", height: "48px", marginBottom: "1rem" }} >
							<LendIcon />
						</div>
						<h2>Lend</h2>
						<p><strong>Lend your ARTT to earn rewards</strong></p>
						<Link to="/lend" className='button'>Lend</Link>
					</div>
				</FlexContainer>
			</FlexContainer>
		</main >
	);
};

export default Home;