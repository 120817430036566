import styled from 'styled-components';

export const StyledInput = styled.input`
  background-color: ${ ({ theme }) => theme.input.backgroundColor };
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border: 3px solid rgb(194, 206, 223);
  box-shadow: none;
  color: ${ ({ theme }) => theme.color };
  font-size: 1rem;
  font-weight: bold;
  height: fit-content;
  max-width: ${ props => (props.maxWidth ? props.maxWidth : 'auto') };
  min-height: 40px;
  min-width: 0;
  padding: 12px;
  text-align: ${ props => (props.align ? props.align : 'flex-start') };
  width: ${ props => (props.width ? props.width : '100%') };

  :focus {
    box-shadow: ${ props => (props.readOnly ? 'none' : '0 0 0 2px rgba(2, 158, 234, .5)') };
    outline: none;
  }

  &[readonly] {
    cursor: not-allowed;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;