import styled from 'styled-components';

export const Item = styled.div`
  align-items: center;
  border-radius: 10px;
  border: ${ ({ border, theme }) => border ? theme.border : 'none' };
  box-shadow: none;
  box-sizing: border-box;
  color: ${ ({ theme }) => theme.color };
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: bold;
  height: 44px;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  width: ${ props => props.width ? props.width : '66px' };
`;

export const RadioButtonLabel = styled.label`
  background-color: rgb(81, 154, 250);
  border-radius: 5px;
  border: none;
  box-shadow: ${ ({ theme }) => theme.button.boxShadow };
  color: #FFF;
  font-size: 1rem;
  font-weight: 600;
  height: 100%;
  left: 0;
  padding-top: 10px;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const RadioButton = styled.input`
  cursor: pointer;
  height: 100%;
  margin-right: 10px;
  opacity: 0;
  width: 100%;
  z-index: 1;

  &:hover ~ ${ RadioButtonLabel } {
    background: #339CF5;
    color: #FFF;
    transition: all 200ms ease-in-out
  }

  &:checked + ${ Item } {
    background: #0275D8;
    border: 2px solid #0275D8;
    box-shadow: 0 2px 2px rgb(2 158 234 / 20%);
    color: #FFF;
    opacity: 1;
    top: 2px;
    transition: all 200ms ease-in-out
  }

  &:checked + ${ RadioButtonLabel } {
    background: #015195;
    box-shadow: 0 2px 2px rgb(2 158 234 / 20%);
    color: #FFF;
    opacity: 1;
    top: 2px;
    transition: all 200ms ease-in-out;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;