import { useContractCall } from '@usedapp/core';
import { utils } from 'ethers';
import ARTTLending from '../abi/ArtTokenLending.json';
import { CONTRACTS } from '../utils/constants';

const STAKING_ABI = new utils.Interface(ARTTLending.abi);
const STAKING_ADDRESS = CONTRACTS.BSC.STAKING_ADDRESS;

export function useStakedIndexesPerAddressCount(address) {
	return useContractCall(
		address &&
		{
			abi: STAKING_ABI,
			address: STAKING_ADDRESS,
			method: 'getLentIndexesPerAddressCount',
			args: [ address ],
		}
	) ?? [];
}
