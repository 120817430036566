import { formatUnits } from '@ethersproject/units';
import { useStakedBalancesByAddress } from './useStakedBalancesByAddress';

export function useTotalPendingRewards(address) {
	const stakedBalances = useStakedBalancesByAddress(address);
	let total = 0;

	stakedBalances.forEach(balance => {
		total += balance && +formatUnits(balance?.pendingReward.toString());
	});

	if ( total ) return total;

	return '';
}