import styled from 'styled-components';

export const StyledDiv = styled.div`
  align-items: ${ props => (props.align ? props.align : 'center') };
  background-color: ${ props => (props.backgroundColor ? props.backgroundColor : ({ theme }) => theme.backgroundColor)  };
  border-top-left-radius: ${ props => (props.radiusTopLeft ? props.radiusTopLeft : '14px') };
  border-top-right-radius: ${ props => (props.radiusTopRight ? props.radiusTopRight : '5px') };
  border-bottom-left-radius: ${ props => (props.radiusBottomLeft ? props.radiusBottomLeft : '5px') };
  border-bottom-right-radius: ${ props => (props.radiusBottomRight ? props.radiusBottomRight : '14px') };
  border: ${ ({ border, theme }) => border ? theme.border : 'none' };
  box-shadow: ${ ({ shadow, theme }) => shadow ? theme.boxShadow : 'none' };
  color: ${ ({ theme }) => theme.color };
  display: flex;
  flex-direction: ${ props => (props.direction ? props.direction : 'row') };
  flex-grow: ${ props => (props.flexGrow ? props.flexGrow : '0') };
  flex-wrap: ${ props => (props.wrap ? props.wrap : 'wrap') };
  flex: ${ props => (props.flex ? props.flex : '0 1 auto') };
  gap: ${ props => (props.gap ? props.gap : '10px') };
  height: ${ props => (props.height ? props.height : 'auto') };
  justify-content: ${ props => (props.justify ? props.justify : 'flex-start') };
  margin: ${ props => (props.margin ? props.margin : '0') };
  max-height: ${ props => (props.maxHeight ? props.maxHeight : 'auto') };
  max-width: ${ props => (props.maxWidth ? props.maxWidth : 'auto') };
  min-height: ${ props => (props.minHeight ? props.minHeight : 'auto') };
  min-width: ${ props => (props.minWidth ? props.minWidth : 'auto') };
  overflow: ${ props => (props.overflow ? props.overflow : 'unset') };
  padding: ${ props => (props.padding ? props.padding : '0') };
  row-gap: ${ props => (props.rowGap ? props.rowGap : '10px') };
  width: ${ props => (props.width ? props.width : 'auto') };

  @media (max-width: 480px) {
    .theme_toggle {
      width: 100%;
      margin: 0 2rem;
    }
  }
`;