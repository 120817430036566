import { useContractCall } from '@usedapp/core';
import { utils } from 'ethers';
import ARTTLending from '../abi/ArtTokenLending.json';
import { CONTRACTS } from '../utils/constants';

const STAKING_ADDRESS = CONTRACTS.BSC.STAKING_ADDRESS;
const STAKING_ABI = new utils.Interface(ARTTLending.abi);

export function useTotalRewardsPool() {
	const [ rewardsPool ] = useContractCall(
		{
			abi: STAKING_ABI,
			address: STAKING_ADDRESS,
			method: 'rewardPool',
			args: [],
		}
	) ?? [];

	return rewardsPool;
}
