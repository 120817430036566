import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledButton = styled.button`
  align-items: center;
  background: -o-linear-gradient(190deg, rgb(81, 154, 250), rgb(80, 255, 240));
  background: linear-gradient(260deg, rgb(81, 154, 250), rgb(80, 255, 240));
  border-top-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 3px 3px 2px 0px;
  box-shadow: rgb(0 0 0 / 10%) 3px 3px 2px 0px;
  color: ${ ({ theme }) => theme.color };
  cursor: pointer;
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  gap: 5px;
  height: unset;
  width: unset;
  justify-content: center;
  line-height: 1.5rem;
  margin: ${ props => (props.margin ? props.margin : '0') };
  outline: 0;
  overflow: hidden;
  padding: 0.9rem 2rem 0.9rem 2rem;
  position: relative;
  text-align: center;
  top: 0;
  transition: background 400ms;
  vertical-align: center;

  &:hover {
    color: #FFF;
    -webkit-box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
    box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
  }

  &:active:hover {
    -webkit-box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
    box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
    top: 2px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const A = styled.a`
  align-items: center;
  display: inline-flex;
  flex: none;
  text-decoration: none;
`;

export const StyledLink = styled(Link)`
  align-items: center;
  display: inline-flex;
  flex: none;
  text-decoration: none;
`;

export const StyledButtonBlock = styled(StyledButton)`
  margin: ${ props => (props.margin ? props.margin : 'auto') };

  &:hover {
    background: -o-linear-gradient(190deg, rgb(0, 0, 0), rgb(0, 0, 0));
    background: linear-gradient(260deg, rgb(0, 0, 0), rgb(0, 0, 0));
    -webkit-box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
    box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
  }

  &:active:hover {
    -webkit-box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
    box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
    top: 2px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledOutlineButtonBlock = styled(StyledButton)`
  background-color: ${ ({ theme }) => theme.backgroundColor };
  box-shadow: ${ ({ theme }) => theme.button.boxShadow };
  color: ${ ({ theme }) => theme.color };
  height: 40px;
  margin: ${ props => (props.margin ? props.margin : 'auto') };
  width: 100%;
`;

export const StyledConnectButton = styled(StyledButton)`
  color: ${ ({ theme }) => theme.color };
  flex: 1 1 auto;
  top: 0;

  &:hover {
    background: -o-linear-gradient(190deg, rgb(0, 0, 0), rgb(0, 0, 0));
    background: linear-gradient(260deg, rgb(0, 0, 0), rgb(0, 0, 0));
    -webkit-box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
    box-shadow: rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px;
  }

  &:active:hover {
    top: 0;
  }
`;

// TODO: add style
export const StyledPrimaryButton = styled(StyledButton)``;
export const StyledWarnButton = styled(StyledButton)``;
export const StyledOutlineButton = styled(StyledButton)``;